import { createStore } from "vuex";

const state = {
  user: {
    loggedIn: false,
    data: null
  },
  loading: true,
  isNavOpen: false
};
const getters = {
  user(state) {
    return state.user;
  }
};
const mutations = {
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    if (
      data != null &&
      (data["username"] == undefined || data["username"] == "")
    ) {
      data["username"] = data["email"].split("@")[0];
    }

    state.user.data = data;
  },
  TOGGLE_NAV() {
    state.isNavOpen = !state.isNavOpen;
  }
};
const actions = {
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);

    if (user) {
      commit("SET_USER", {
        username: user.displayName,
        email: user.email,
        admin: user.admin,
        uid: user.uid,
        assessment1: user.assessment1,
        assessment2: user.assessment2,
        assessment3: user.assessment3,
        mentorship: user.mentorship,
        revision: user.revision
      });
    } else {
      commit("SET_USER", null);
    }
  }
};

export default createStore({
  state,
  getters,
  mutations,
  actions
});
