!<template>
  <div>
    <div class="mx-3 my-3">
      <h1 class="ml-4 font-weight-bold h2 head">Revision Help</h1>
      <ul>
        <li>
          This module provides you with questions and answers, in which answers
          are hidden and can be seen. It provides you a way to challenge
          yourself and make the revision process interesting.
        </li>
        <li>
          The revision module covers the entire syllabus and provides in depth
          coverage of the syllabus.
        </li>
        <li>
          You can access all sections of the entire syllabus once enrolled.
        </li>
      </ul>
      <h4 class="ml-4 font-weight-bold h2 head">How to Use</h4>
      <ul>
        <li>Please enroll in the Revision Program.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {

  font-size: 18px;
  line-height: 1.7;

}
</style>