
import MainHelp from './MainHelp.vue'
import HelpAssessment from './HelpAssessment.vue'
import HelpMentorship from './HelpMentorship.vue'
import HelpRevision from './HelpRevision.vue'
import HelpSchedule from './HelpSchedule.vue'
import HelpEssay from "./HelpEssay.vue"
import Help from './Help.vue'
export default 
  {
    path: '/help/',
    name: 'Help',
    component: Help,
    meta:{title: 'Help'},
   
    children: [
      {
        path: 'help-assessment',
        name: 'HelpAssessment',
        component: HelpAssessment,
      },
      {
        path: 'main-help',
        name: 'MainHelp',
        component: MainHelp,
      },
      {
        path: 'help-mentorship',
        name: 'HelpMentorship',
        component: HelpMentorship,
      },
      {
        path: 'help-revision',
        name: 'HelpRevision',
        component: HelpRevision,
      },
      {
        path: 'help-essay',
        name: 'HelpEssay',
        component: HelpEssay,
      },
      {
        path: 'help-schedule',
        name: 'HelpSchedule',
        component: HelpSchedule,
      },
    ],
  }
