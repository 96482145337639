<template>
  <div>
  
    <div class="mx-3 my-3">
      <h1 class="ml-4 font-weight-bold h2 head">Assessment Help</h1>
      <ul>
        <li>
          There are three (03) types of Assessments viz. Quick, Standard and
          Comprehensive but each of them tests the student for the entire
          syllabus of the civil services exam.
        </li>
        <li>
          The questions consist of MCQs, fill in the blanks, short answer and
          long answer questions.
          <strong>The questions other than MCQs need to be typed</strong>. Thus,
          adequate time has been provided for typing the answers.
        </li>
        <li>
          These assessments are divided as such due to their features:-
          <ul>
            <li>
              Quick - less number of questions with faster attempt and lesser
              accuracy to assess the student. It is suitable for initial
              assessment of students and if a student is going for their first
              attempts on a short notice.
            </li>
            <li>
              Standard - adequate number of questions for appropriate accuracy
              to assess the student. It is suitable for assessment of all
              students and is recommended for a normal attempt.
            </li>
            <li>
              Comprehensive- adequate number of questions for appropriate
              accuracy to assess the student. It is suitable for assessment of
              all students and is recommended for a normal attempt.
            </li>
          </ul>
        </li>
        <li>
          You can go to ‘Test Pending’ to attempt any test that you haven't
          attempted yet , you'll be shown a list of unattempted tests which you
          can attempt.
        </li>
        <li>
          Kindly do not start a test, unless you wish to finish it as it gets
          auto-submitted after time expires. Please make sure you have 4 hours
          for quick assessment, 8 hours for standard assessment and 12 hours for
          comprehensive assessment (though not in one sitting).<strong
            >The assessments however provides you the option to save responses
            (auto-save when you press next) and go back home without
            submitting.</strong
          >
          Hence assessments may be given in shifts (this is advised for standard
          and comprehensive assessments).
        </li>
        <li>
          Once you have completed some test you can go to ‘Test Reports’ where
          you can view ‘Full Test Stats’ (This page is dynamic and changes with
          time your AIR and percentile will change as more students will attempt
          this test). You also go to the profile menu to check your past
          performance and compare your previous assessments.
        </li>
        <li>
          You can also go to ‘Show Responses’ once your teachers have reviewed
          your test where you can view your full brief and detailed analysis,
          see remarks given to you by your teacher and also download your report
          card. The remarks will highlight the sections you are weak in and will
          also suggest methods/ books/ materials to improve yourself.
        </li>
      </ul>
      <h4 class="ml-4 font-weight-bold h2 head">How to Use</h4>
      <ul>
        <li>
          The quick, standard and comprehensive assessments need to be bought
          separately which will be activated once the payment has been received
          and confirmed by KnowPrep.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {
  font-size: 18px;
  line-height: 1.7;
}

</style>