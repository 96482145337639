import firebase from "firebase/app"
import 'firebase/firestore'; 
const app = firebase.initializeApp({
    apiKey: "AIzaSyCtwB9hZC4ztZqwCJ6B6bdDvuHhfk3btOM",
    authDomain: "psyberduck-fccf1.firebaseapp.com",
    databaseURL: "https://psyberduck-fccf1-default-rtdb.firebaseio.com",
    projectId: "psyberduck-fccf1",
    storageBucket: "psyberduck-fccf1.appspot.com",
    messagingSenderId: "575940253086",
    appId: "1:575940253086:web:7ed7233b3caf2c168df28f",
    measurementId: "G-E2Y0S0Q8FN"
})

const db = app.firestore()
db.settings({
    timestampsInSnapshots:true
})
export default db
