<template>
  <div id="preloader" v-if="loading">
    <div class="sk-wave">
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
      <div class="sk-wave-rect"></div>
    </div>
  </div>
  <div id="nav">
    <router-view />
  </div>
</template>
<style>
@import "./index.css";
</style>
<style scoped>
#preloader {
  display: flex;
  justify-content: center;
  color: red;
  align-items: center;
  height: 100vh;
  --sk-size: 40px;
  --sk-color: red;
}
</style>
<script>
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, nextTick } from "vue";
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import store from "./store";

export default {
  computed: {
    loading: function () {
      return store.state.loading;
    },
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      console.log("Before mount");
      store.state.loading = true;
      firebase.auth().onAuthStateChanged((user) => {
        console.log("Changing auth");

        store.dispatch("fetchUser", user).then(() => {
          if (!user) {
            console.log(route.path);

            if (route.path == "/demo" || route.path.startsWith("/plans")) {
              //dont do anything
            } else router.replace("/login");
          } else if (route.path == "/login") {
            console.log("Logged in user");
            user.getIdTokenResult().then((id) => {
              if (id.claims.admin) {
                store.state.user.data.admin = true;
                router.replace("/admin");
              } else {
                store.state.user.data.admin = false;
                router.replace("/");
              }
              store.state.user.data.assessment1 = id.claims.assessment1;
              store.state.user.data.assessment2 = id.claims.assessment2;
              store.state.user.data.assessment3 = id.claims.assessment3;
              store.state.user.data.mentorship = id.claims.mentorship;
              store.state.user.data.revision = id.claims.revision;
            });
          } else {
            user.getIdTokenResult().then((id) => {
              // console.log("setted")
              if (id.claims.admin) store.state.user.data.admin = true;
              else store.state.user.data.admin = false;
              store.state.user.data.assessment1 = id.claims.assessment1;
              store.state.user.data.assessment2 = id.claims.assessment2;
              store.state.user.data.assessment3 = id.claims.assessment3;
              store.state.user.data.mentorship = id.claims.mentorship;
              store.state.user.data.revision = id.claims.revision;
            });
          }
        });
      });
    });
    router.beforeEach((to, from, next) => {
      let DEFAULT_TITLE = "KnowPrep";
      nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
      });
      if (to.name == "Demo") return next();
      if (to.name == "DemoQuiz") return next();
      if (to.name == "DemoResponses") return next();
      if (to.name == "Checkout") return next();
      else if (to.name == "Login") {
        return next();
      } else {
        var func = () => {
          setTimeout(() => {
            if (store.state.user.data == null) {
              setTimeout(func, 1000);
            } else {
              const userRoutes = [
                "Home",
                "Quiz",
                "Results",
                "Reports",
                "Stats",
                "TestNotGiven",
                "EditProfile",
                "Assessment",
                "Schedule",
                "Revision",
                "PermissionNotFound",
                "AllRevisions",
                "ViewRevision",
                "Instructions",
                "Help",
                "HelpAssessment",
                "MainHelp",
                "HelpMentorship",
                "HelpRevision",
                "HelpSchedule",
                "HelpEssay",
                "MyResponses",
                "BreifAnalysis",
                "DetailedAnalysis",
                "faqs",
                "Feedback",
                "PastPerformance",
                "Brief",
                "Detailed",
                "Essay",
                "Proforma",
              ];
              const adminRoutes = [
                "Admin",
                "EditTest",
                "CreateTest",
                "PreviousTest",
                "TestNotifications",
                "TimeTableNotifications",
                "AddUser",
                "ReviewTest",
                "ReviewTimeTable",
                "Stats",
                "EditProfile",
                "Assessment",
                "Schedule",
                "Revision",
                "CreateRevision",
                "PreviousRevision",
                "EditRevision",
                "PermissionNotFound",
                "Help",
                "HelpAssessment",
                "MainHelp",
                "HelpMentorship",
                "HelpRevision",
                "HelpSchedule",
                "HelpEssay",
                "Instructions",
                "GoogleDoc",
                "questionbank",
                "MyResponses",
                "BreifAnalysis",
                "DetailedAnalysis",
                "Reports",
                "Assessment",
                "CreateTest",
                "CreateTest2",
                "PreviousTest",
                "AutoTest",
                "Feedbacks",
                "Brief",
                "Detailed",
                "MyResponses",
                "Essay",
                "EssayNotification",
                "CreateDemoTest",
                "EditDemoTest",
                "DemoMain",
                "PreviousDemoTest",
                "Showcase",
              ];
              console.log(to);
              if (
                (store.state.user.data.admin == false ||
                  store.state.user.data.admin == undefined) &&
                !userRoutes.includes(to.name)
              )
                return next("/");
              else if (
                (store.state.user.data.admin == false ||
                  store.state.user.data.admin == undefined) &&
                userRoutes.includes(to.name)
              ) {
                if (
                  to.params.page_id == 1 &&
                  store.state.user.data.assessment1 != true
                ) {
                  return next("/permission-not-found/");
                } else if (
                  to.params.page_id == 2 &&
                  store.state.user.data.assessment2 != true
                ) {
                  return next("/permission-not-found/");
                } else if (
                  to.params.page_id == 3 &&
                  store.state.user.data.assessment3 != true
                ) {
                  return next("/permission-not-found/");
                } else if (
                  to.name == "Schedule" &&
                  store.state.user.data.mentorship != true
                ) {
                  return next("/permission-not-found/");
                } else if (
                  to.name == "Revision" &&
                  store.state.user.data.revision != true
                ) {
                  return next("/permission-not-found/");
                }
              } else if (
                store.state.user.data.admin == true &&
                !adminRoutes.includes(to.name)
              )
                return next("/admin");

              return next();
            }
          }, 1000);
        };
        func();
      }
    });
  },
};
</script>
