<template>
  <div id="container">
    <div class="ml-4 d-flex flex-column">
      <h1 class="ml-4 font-weight-bold h2 head">General Help</h1>
      <ul>
        <li>
          We have various programs which help you in different phases of
          examination. You can either buy individual programs or go for all five
          of them.
        </li>
        <li>
          You will be allowed access to any program after you have bought its
          subscription other than essay & answer writing, proforma and help
          sections.
        </li>
        <li>The student dashboard helps you keep track of your preparation.</li>
        <li>
          If you are enrolled in our <strong>mentorship</strong> course, then
          you automatically get access to all our resources.
        </li>
        <li>
          The <strong>assessment</strong> program is for finding out if you are
          ready for the civil services exam or not.
        </li>
        <li>
          <strong>Schedule making</strong> program is for making an ideal
          schedule for yourself which is customized to your circumstances.
        </li>
        <li>
          <strong>Revision</strong> provides you a method to revise the entire
          syllabus in Q&A format instead of just a text write up.
        </li>
        <li>
          <strong>Essay and Answer writing</strong> provides a portal where you
          can either join our essay and answer writing course OR get your essays
          and answers checked from our professionals.
        </li>
        <li>
          You can find about each of them in their individual help sections!
        </li>
        <li>
          The payment to each section can be done through account details sent
          to you and other payment methods. KnowPrep will contact you once you
          sign up!
        </li>
        <li>
          For any query please call our number or whatsapp/ telegram/ message us
          on +91-8287663672 and mail us on KnowPrep@gmail.com.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {
  font-size: 18px;
  line-height: 1.7;
}
</style>


