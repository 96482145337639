<template>
  <div class="header">
    <nav class="navbar navbar-expand-md navbar-light bg-light" id="header">
      <span class="goto" @click="gotohome" href="#" style="margin-right: 10px"
        ><img
          src="@/assets/logo_transparent.png"
          alt=""
          style="margin-right: 0px; height: 50px"
      /></span>
      <h1 @click="gotohome" class="logo goto mr-auto"><span>Know</span>prep</h1>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarNavDropdown" class="collapse navbar-collapse">
        <ul class="navbar-nav nav ml-auto">
          <li class="nav-item" v-if="!user.data.admin">
            <a
              class="nav-link"
              v-bind:class="{ active: isActive('Home') }"
              href="/"
              >Home</a
            >
          </li>
          <li class="nav-item" v-if="user.data.admin">
            <a
              class="nav-link"
              v-bind:class="{ active: isActive('Home') }"
              href="/admin"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="{ active: isActive('mentorship') }"
              href="/schedule"
              >Schedule Making</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-bind:class="{ active: isActive('revision') }"
              href="/revision"
              >Revision</a
            >
          </li>
          <li class="nav-item" v-if="user.data.admin">
            <a
              class="nav-link"
              href="/add-user"
              v-bind:class="{ active: isActive('adduser') }"
              >Add User</a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link"
              v-bind:class="{ active: isActive('assessment') }"
              data-toggle="dropdown"
              >Assessments</a
            >
            <div class="dropdown-menu dropdown-menu-right mynav">
              <a class="dropdown-item" href="/assessment/1/">Quick</a>
              <a class="dropdown-item" href="/assessment/2/">Standard</a>
              <a class="dropdown-item" href="/assessment/3/">Comprehensive</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              href="#"
              class="nav-link"
              data-toggle="dropdown"
              v-bind:class="{ active: isActive('profile') }"
              >Profile</a
            >
            <div class="dropdown-menu dropdown-menu-right mynav">
              <h6 v-if="user.data" class="dropdown-item myitem">
                Hello {{ user.data.username }}
              </h6>
              <a class="dropdown-item" href="/edit">Edit Profile</a>
              <a
                class="dropdown-item"
                v-if="!user.data.admin"
                href="/past-performance"
                >Past Performnce</a
              >
              <a class="dropdown-item" v-if="!user.data.admin" href="/feedback"
                >Feedback</a
              >
              <a class="dropdown-item" v-else href="/feedbacks">Feedbacks</a>
              <a class="dropdown-item" v-if="user.data.admin" href="/showcase"
                >Showcase</a
              >
              <a class="dropdown-item" href="/help/main-help">Help</a>
              <a class="dropdown-item" href="/login" @click="logout">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<style scoped>
.goto {
  cursor: pointer;
}
.header {
  z-index: 10000000;
}
.nav-item > .nav-link {
  color: black;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 7px 10px;
  position: relative;
}
a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: red;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after {
  width: 100%;
  left: 0;
}
#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.mynav {
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
  transition: 0.3s;
  border-top: 2px solid #d42525;
}
#header .logo {
  color: #111;
}
#header .logo span {
  color: #d42525;
}
.myitem {
  display: block;
  font-weight: bold;
}
.myitem:hover {
  color: #212529;
  background-color: transparent;
}
.myitem:active {
  color: #212529;
  background-color: transparent;
}
.bg-light {
  background-color: rgba(256, 256, 256, 1) !important;
}

.active {
  color: red !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  props: ["active_tab"],
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      username: "Aryan",
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then((data) => data)
        .catch((err) => swal(err));
    },
    isActive(data) {
      return this.active_tab === data;
    },
    gotohome() {
      // console.log('asd');
      window.location = "https://dashboard.knowprep.in/";
    },
  },
};
</script>
