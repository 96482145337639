<template>
  <div>
   <div class="mx-3 my-3">
      <h1 class="ml-4 font-weight-bold h2 head">Mentorship Help</h1>
      <ul>
       <li>This program provides complete hand holding by our CSE qualified mentors. This also opens access to all our resources.</li>
       <li>The program includes assessment for finding out weak areas, schedule making for an ideal schedule, 24 hour access to our mentors for doubt clarification and also active teaching.</li>
       <li>It comes with the benefit of self revision through revision program and unlimited essay & answer writing / checking for the student.</li>
      </ul>
      <h4 class="ml-4 font-weight-bold h2 head">How to Use</h4>
      <ul>
        <li>You have to apply for the mentorship program. You may request a specific mentor and the mentor will be assigned to you as per their availability.</li>
      </ul>
</div>
</div>
</template>

<script>
import store from '@/store';
export default {
mounted(){
  store.state.loading=false;
}
}
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {

  font-size: 18px;
  line-height: 1.7;

}
</style>