import { createRouter, createWebHistory } from "vue-router";

const Home = () => import("../views/Home.vue");
const Quiz = () => import("../views/Quiz.vue");
const Results = () => import("../views/Results.vue");
const MyResponses = () => import("../views/MyResponses/MyResponses");
const BriefAnalysis = () => import("../views/MyResponses/BriefAnalysis");
const DetailedAnalysis = () => import("../views/MyResponses/DetailedAnalysis");
const Stats = () => import("../views/Stats.vue");
const Reports = () => import("../views/Reports.vue");
const Login = () => import("../views/Login.vue");
const EditProfile = () => import("../views/EditProfile.vue");
const Feedback = () => import("../views/Feedback.vue");
const Feedbacks = () => import("../views/Feedbacks.vue");

const Admin = () => import("../views/Admin.vue");
const CreateTest = () => import("../views/admin-screens/CreateTest.vue");
const CreateTest2 = () => import("../views/admin-screens/CreateTest2.vue");
const PreviousTest = () => import("../views/admin-screens/PreviousTest.vue");
const TestNotifications = () =>
  import("../views/admin-screens/TestNotifications.vue");
const TimeTableNotifications = () =>
  import("../views/admin-screens/TimeTableNotifications.vue");
const AddUser = () => import("../views/admin-screens/AddUser.vue");
const EditTest = () => import("../views/admin-screens/EditTest.vue");
const AutoTest = () => import("../views/admin-screens/AutoTest.vue");
const ReviewTest = () => import("../views/admin-screens/ReviewTest.vue");
const ReviewTimeTable = () => import("../views/admin-screens/ReviewTimeTable");
const TestNotGiven = () => import("../views/TestNotGiven.vue");
const Assessment = () =>
  import("../views/admin-screens/Assessment/assessment.vue");
const Mentorship = () =>
  import("../views/admin-screens/Mentorship/Schedule Making.vue");
const Revision = () => import("../views/admin-screens/Revision/revision.vue");
const CreateRevision = () => import("../views/admin-screens/CreateRevision");
const EditRevision = () => import("../views/admin-screens/EditRevision");
const PreviousRevision = () =>
  import("../views/admin-screens/PreviousRevision");
const PermissionNotFound = () => import("../views/PermissionNotFound");
const Instructions = () => import("../views/Instructions");
const AllRevisions = () => import("../views/AllRevisions.vue");
const ViewRevision = () => import("../views/ViewRevision.vue");
const PastPerformance = () => import("../views/PastPerformance.vue");
const questionbank = () => import("../views/admin-screens/QuestionBank.vue");
const GoogleDocs = () => import("@/views/admin-screens/GoogleDocs");
const Essay = () => import("@/views/Essay");
const EssayUploadNotifcation = () => import("@/views/EssayUploadNotification");
const Checkout = () => import('@/views/Checkout');
const Proforma = () => import("@/views/Proforma");
import helpRoutes from "../views/help/router";
//Demo
const DemoMyResponse = () => import("@/views/demo/DemoMyResponse");
const DemoHome = () => import("@/views/demo/DemoHome");
const DemoQuiz = () => import("@/views/demo/DemoQuiz");
const EditDemoTest = () => import("@/views/demo/EditDemoTest");
const CreateDemoTest = () => import("@/views/demo/CreateDemoTest");
const DemoMain = () => import("@/views/demo/DemoMain");
const PreviousDemoTest = () => import('@/views/demo/PreviousDemoTest');
const Showcase = () => import("../views/admin-screens/Showcase.vue");

const baseRoutes = [
  {
    path: "/",
    name: "Home",
    title: "Home",
    component: Home,
    meta: {
      title: "Student Dashboard"
    }
  },
  {
    path: "/permission-not-found/",
    name: "PermissionNotFound",
    component: PermissionNotFound,
    meta: {
      title: "Not Found"
    }
  },
  {
    path: "/all-revisions/:revision_name",
    name: "AllRevisions",
    component: AllRevisions,
    props: true,
    meta: {
      title: "All Revisions"
    }
  },
  {
    path: "/revision-view/:revision_id/",
    name: "ViewRevision",
    component: ViewRevision,
    props: true,
    meta: {
      title: "Revision View"
    }
  },
  {
    path: "/edit",
    name: "EditProfile",
    component: EditProfile,
    meta: {
      title: "Edit Profile"
    }
  },
  {
    path: "/questionbank",
    name: "questionbank",
    component: questionbank,
    meta: {
      title: "Question Bank"
    }
  },
  {
    path: "/quiz/:page_id/:id",
    name: "Quiz",
    component: Quiz,
    props: true,
    meta: {
      title: "Quiz"
    }
  },
  {
    path: "/results/:page_id",
    name: "Results",
    component: Results,
    props: true,
    meta: {
      title: "Results"
    }
  },

  {
    path: "/responses/:page_id/:test_id",
    name: "MyResponses",
    component: MyResponses,
    props: true,
    children: [
      {
        path: "",
        name: "parent",
        redirect: {
          name: "Brief"
        }
      },
      {
        path: "brief",
        name: "Brief",
        component: BriefAnalysis
      },
      {
        path: "detailed",
        name: "Detailed",
        component: DetailedAnalysis
      }
    ]
  },
  {
    path: "/tests/:page_id",
    name: "TestNotGiven",
    component: TestNotGiven,
    props: true,
    meta: {
      title: "Tests"
    }
  },
  {
    path: "/instructions/:page_id/:test_id",
    name: "Instructions",
    component: Instructions,
    props: true,
    meta: {
      title: "Instructions"
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      title: "Reports"
    }
  },
  {
    path: "/stats/:page_id/:id",
    name: "Stats",
    component: Stats,
    props: true,
    meta: {
      title: "Stats"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login"
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "Admin Dashboard"
    }
  },
  {
    path: "/assessment/:page_id",
    name: "Assessment",
    component: Assessment,
    props: true,
    meta: {
      title: "Assessment"
    }
  },
  {
    path: "/schedule/",
    name: "Schedule",
    component: Mentorship,
    meta: {
      title: "Schedule "
    }
  },
  {
    path: "/revision/",
    name: "Revision",
    component: Revision,
    meta: {
      title: "Revision"
    }
  },
  {
    path: "/create-revision/",
    name: "CreateRevision",
    component: CreateRevision,
    meta: {
      title: "Create Revision"
    }
  },
  {
    path: "/previous-revision/",
    name: "PreviousRevision",
    component: PreviousRevision,
    meta: {
      title: "Previous Revision"
    }
  },
  {
    path: "/edit-revsion/:revision_id/",
    name: "EditRevision",
    component: EditRevision,
    props: true,
    meta: {
      title: "Edit Revision"
    }
  },
  {
    path: "/create-test/:page_id",
    name: "CreateTest",
    component: CreateTest,
    props: true
  },
  {
    path: "/create-test2/:test_id/:page_id",
    name: "CreateTest2",
    component: CreateTest2,
    props: true,
    meta: {
      title: "Create Test"
    }
  },
  {
    path: "/previous-test/:page_id/",
    name: "PreviousTest",
    component: PreviousTest,
    props: true,
    meta: {
      title: "Previous Test "
    }
  },
  {
    path: "/edit-test/:page_id/:test_id",
    name: "EditTest",
    component: EditTest,
    props: true,
    meta: {
      title: "Edit Test"
    }
  },
  {
    path: "/auto-test/:page_id/:test_id",
    name: "AutoTest",
    component: AutoTest,
    props: true,
    meta: {
      title: "Automated Test"
    }
  },
  {
    path: "/test-notifications/:page_id/",
    name: "TestNotifications",
    component: TestNotifications,
    props: true,
    meta: {
      title: "Test Notifications"
    }
  },
  {
    path: "/time-table-notifications/",
    name: "TimeTableNotifications",
    component: TimeTableNotifications,
    meta: {
      title: "Time Table Notifications"
    }
  },
  {
    path: "/add-user/",
    name: "AddUser",
    component: AddUser,
    props: true,
    meta: {
      title: "Add Users"
    }
  },
  {
    path: "/review-test/:page_id/:test_id/:user_test_id",
    name: "ReviewTest",
    component: ReviewTest,
    props: true,
    meta: {
      title: "Review Test"
    }
  },
  {
    path: "/review-time-table/:user_id",
    name: "ReviewTimeTable",
    component: ReviewTimeTable,
    props: true,
    meta: {
      title: "Mentorship - Admin"
    }
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      title: "Feedback"
    }
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    component: Feedbacks,
    meta: {
      title: "Feedbacks"
    }
  },
  {
    path: "/past-performance",
    name: "PastPerformance",
    component: PastPerformance,
    meta: {
      title: "PastPerformance"
    }
  },
  {
    path: "/google-docs-upload",
    name: "GoogleDoc",
    component: GoogleDocs,
    meta: {
      title: "Google Docs"
    }
  },
  {
    path: "/essay",
    name: "Essay",
    component: Essay,
    meta: {
      title: "Essay"
    }
  },
  {
    path: "/essay-notification",
    name: "EssayNotification",
    component: EssayUploadNotifcation,
    meta: {
      title: "Essay Notifications"
    }
  },
  {
    path: "/proforma",
    name: "Proforma",
    component: Proforma,
    meta: {
      title: "Proforma"
    }
  },
  {
    path: "/demo",
    component: DemoHome,
    name: "Demo"
  },
  {
    path: "/demo-quiz",
    component: DemoQuiz,
    name: "DemoQuiz"
  },
  {
    path: "/create-test-demo",
    component: CreateDemoTest,
    name: "CreateDemoTest"
  },
  {
    path: "/edit-demo-test",
    component: EditDemoTest,
    name: "EditDemoTest",
    props: true,
    meta: {
      title: "Edit Demo Test"
    }
  },
  {
    path: "/demo-main",
    component: DemoMain,
    name: "DemoMain"
  },
  {
    path: "/previous-test-demo",
    component: PreviousDemoTest,
    name: "PreviousDemoTest"
  },
  {
    path: "/plans/:plan_name",
    component: Checkout,
    props: true,
    name: "Checkout"
  },
  {
    path: "/showcase",
    component: Showcase,
    name: "Showcase"
  },

  {
    path: "/demo-responses",
    name: "DemoResponses",
    component: DemoMyResponse
    // props: true
    // children: [
    //   {
    //     path: "brief",
    //     name: "DemoBrief",
    //     component: DemoBriefAnalysis
    //   },
    //   {
    //     path: "detailed",
    //     name: "DemoDetailed",
    //     component: DemoDetailedAnalysis
    //   }
    // ]
  }
];
const routes = baseRoutes.concat(helpRoutes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
