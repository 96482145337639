<template>
  <div class="mx-3 my-3">
    <h1 class="ml-4 font-weight-bold h2 head">Essay Help</h1>
    <ul>
      <li>This portal is available to you once you register.</li>
      <li>
        You can upload the essay or your answers on the portal in PDF format.
        This will be checked and the feedback will be provided by our
        professionals once concerned payment is done.
      </li>
      <li>For this you may contact KnowPrep and SignUp.</li>
    </ul>
  </div>
</template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {

  font-size: 18px;
  line-height: 1.7;

}
</style>