import "./firebase";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueHtml2Canvas from "vue-html2canvas/src";

import { VueCookieNext } from "vue-cookie-next";
global.jQuery = require("jquery");


//disbaling right click and blocking copy download functions
// document.onkeydown = function(e) {
//   if (e.ctrlKey && e.key == "c") {
//     alert("not allowed");
//     return false;
//   }
//   if (e.ctrlKey && e.key == "v") {
//     alert("not allowed");
//     return false;
//   }
//   if (e.ctrlKey && e.key == "p") {
//     alert("not allowed");
//     return false;
//   }
// };
// document.addEventListener("contextmenu", event => event.preventDefault());

/////
createApp(App).use(store)
  .use(VueCookieNext)
  .use(VueHtml2Canvas)
  .use(router)
  .mount("#app");
