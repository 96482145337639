<template>
  <div id="container1">
    <Header active_tab="profile" />
    <hr />
    <div class="dot"></div>
    <div id="nav">
      <router-link :to="{ name: 'MainHelp' }">Help</router-link>
      <router-link :to="{ name: 'HelpAssessment' }">Assessment</router-link>
      <router-link :to="{ name: 'HelpMentorship' }">Mentorship</router-link>
      <router-link :to="{ name: 'HelpSchedule' }">Schedule Making</router-link>
      <router-link :to="{ name: 'HelpEssay' }"
        >Essay and Answer Writing</router-link
      >
      <router-link :to="{ name: 'HelpRevision' }">Revision</router-link>
    </div>
    <router-view> </router-view>
  </div>
</template>

<script>
import Header from "../../components/Header";
import store from "@/store";
export default {
  components: { Header },
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
#container1 {
  background-image: white;
  background-attachment: fixed;
  height: 100vh;
  overflow: hidden;
}
h1 {
  padding: 30px;
  font-weight: bold;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #3c3c3c;
  padding-right: 30px;
  font-size: 22px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

#nav a.router-link-exact-active {
  color: rgb(138, 0, 0);
}
hr {
  margin: 0;
}
ul {
  font-size: 18px;
}
.dot {
  position: fixed;
  height: 1000px;
  width: 1000px;
  background-color: red;
  border-radius: 50%;
  opacity: 0.4;

  right: -500px;
  bottom: -40px;

  z-index: -2;
}
</style>
