<template>
  <div class="mx-3 my-3">
    <h1 class="ml-4 font-weight-bold h2 head">Schedule Help</h1>
    <ul>
      <li>
        This page contains all your previously uploaded time table reports.
      </li>
      <li>
        The schedule is provided to you after our professionals get in touch
        with you and get all your basic data. The basic data is provided through
        Proforma (available to be filled on the proforma page).
      </li>
      <li>
        The schedule is accessible to you on the “Open Google Docs” button on
        the Time Table reports page in Schedule making. It will redirect you to
        the Google docs where your schedule is uploaded.
      </li>
      <li>
        <strong
          >You only need to fill the first sheet (Daily). The codes for daily
          activity are given in the ‘WEEK’ sheet (for example SL for ‘sleep’).
          The codes for various subjects to be filled are given in ‘ANNUAL’
          sheet. Each column in the Daily sheet depicts 24 hours. You need to
          fill the activity done by you in that hour for the entire day. Your
          Daily, Monthly and Annual targets are given in their respective sheets
          (Daily targets are in Week sheet).</strong
        >
      </li>
      <li>
        You can select ‘start date’ and ‘last date’ and upload a new time table
        to get it reviewed by KnowPrep. The available graphs will show you your
        performance in the given time period.
      </li>
      <li>
        If you haven't uploaded any time table yet you can download a sample
        excel sheet to show you how to fill the data. It is available on the
        Time Table reports page in Schedule making.
      </li>
      <li>
        Once KnowPrep has reviewed your time table charts you will be able see
        the review on this page itself. The review will provide you the way
        ahead to improve your performance and KnowPrep will get in touch with
        you periodically to suggest measures for improving time management.
      </li>
    </ul>
  </div>
</template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.state.loading = false;
  },
};
</script>

<style scoped>
.head {
  color: rgb(43, 43, 43);
}
ul {

  font-size: 18px;
  line-height: 1.7;

}
</style>